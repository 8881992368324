@font-face {
    font-family: lato;
    src:
            url(https://use.typekit.net/af/220823/000000000000000000015231/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3)
            format("woff2"),
            url(https://use.typekit.net/af/220823/000000000000000000015231/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3)
            format("woff"),
            url(https://use.typekit.net/af/220823/000000000000000000015231/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3)
            format("opentype");
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    font-display: auto;
}
@font-face {
    font-family: lato;
    src:
            url(https://use.typekit.net/af/180254/00000000000000000001522c/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3)
            format("woff2"),
            url(https://use.typekit.net/af/180254/00000000000000000001522c/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3)
            format("woff"),
            url(https://use.typekit.net/af/180254/00000000000000000001522c/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3)
            format("opentype");
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    font-display: auto;
}

@font-face {
    font-family: lato;
    src:
            url(https://use.typekit.net/af/a2df1e/00000000000000000001522a/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3)
            format("woff2"),
            url(https://use.typekit.net/af/a2df1e/00000000000000000001522a/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3)
            format("woff"),
            url(https://use.typekit.net/af/a2df1e/00000000000000000001522a/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n3&v=3)
            format("opentype");
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    font-display: auto;
}
@font-face {
    font-family: freight-display-pro;
    src:
            url(https://use.typekit.net/af/868845/0000000000000000000132cb/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3)
            format("woff2"),
            url(https://use.typekit.net/af/868845/0000000000000000000132cb/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3)
            format("woff"),
            url(https://use.typekit.net/af/868845/0000000000000000000132cb/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3)
            format("opentype");
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    font-display: auto;
}
@font-face {
    font-family: freight-display-pro;
    src:
            url(https://use.typekit.net/af/6f4412/0000000000000000000132cd/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3)
            format("woff2"),
            url(https://use.typekit.net/af/6f4412/0000000000000000000132cd/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3)
            format("woff"),
            url(https://use.typekit.net/af/6f4412/0000000000000000000132cd/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3)
            format("opentype");
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    font-display: auto;
}

@font-face {
    font-family: freight-display-pro;
    src:
            url(https://use.typekit.net/af/f15774/0000000000000000000132cf/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3)
            format("woff2"),
            url(https://use.typekit.net/af/f15774/0000000000000000000132cf/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3)
            format("woff"),
            url(https://use.typekit.net/af/f15774/0000000000000000000132cf/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3)
            format("opentype");
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    font-display: auto;
}
@font-face {
    font-family: freight-display-pro;
    src:
            url(https://use.typekit.net/af/52559e/0000000000000000000132d3/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3)
            format("woff2"),
            url(https://use.typekit.net/af/52559e/0000000000000000000132d3/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3)
            format("woff"),
            url(https://use.typekit.net/af/52559e/0000000000000000000132d3/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n5&v=3)
            format("opentype");
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    font-display: auto;
}
@font-face {
    font-family: lamar-pen;
    src:
            url(https://use.typekit.net/af/4f093b/00000000000000003b9ad334/27/l?primer=f4443732c55d0cafe549f66f78bd8cb70e179de096398ab41dcec6c9aa291a85&fvd=n4&v=3)
            format("woff2"),
            url(https://use.typekit.net/af/4f093b/00000000000000003b9ad334/27/d?primer=f4443732c55d0cafe549f66f78bd8cb70e179de096398ab41dcec6c9aa291a85&fvd=n4&v=3)
            format("woff"),
            url(https://use.typekit.net/af/4f093b/00000000000000003b9ad334/27/a?primer=f4443732c55d0cafe549f66f78bd8cb70e179de096398ab41dcec6c9aa291a85&fvd=n4&v=3)
            format("opentype");
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    font-display: auto;
}

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    font-family: "Lato", sans-serif;
}

html,
body {
    max-width: 100vw;
    overflow-x: hidden;
}

body {
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
}

a {
    color: inherit;
    text-decoration: none;
}

.cky-revisit-bottom-left {
    bottom: 83px!important;
    left: 0px!important;
}

.cky-btn-revisit-wrapper {
    width: 30px!important;
    height: 30px!important;
}


.cky-btn-revisit-wrapper .cky-btn-revisit img {
    width: 25px!important;
    height: 25px!important;
}